import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/swiper-bundle.css';

/*--------------------
* Testimonial Section
----------------------*/
export default function Testimonial() {
    const testimonail_list = [
        {id:1, 'image':'', author_name:'Didier Senou', position:'Auto Entrepreneur', description: "Fiacre Tsevi est un véritable professionnel qui apporte passion et compétence à chaque projet. Nous avons engagé Fiacre pour une mission stimulante, et il a non seulement satisfait mais dépassé nos exigences, fournissant des résultats exceptionnels. Hautement recommandé!"},
        {id:2, 'image':'', author_name:'Hyacinthe KOGUE', position:'CEO at Koorsier', description: "Working with Fiacre Tsevi was a game-changer for our project. His attention to detail, creativity, and commitment to delivering high-quality results exceeded our expectations. Fiacre's expertise and professionalism not only enhanced the project but also made the collaboration a truly enjoyable experience."},
    ]

    return (
        <>
        <section className="section testimonial-section">
            <div className="container">
                <div className="grid section-heading">
                    <div className="lg:col-span-6 text-center mx-auto">
                        <h3><span>Testimonial</span></h3>
                    </div>
                </div>
                <Swiper 
                    spaceBetween={30} 
                    slidesPerView={2} 
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    pagination={{ clickable: true }}
                    breakpoints={{
                        0: {
                        slidesPerView: 1,
                        },
                        992:{
                        slidesPerView:2,
                        },
                    }}
                >
                    {
                        testimonail_list.map((val ,i) =>{
                            return <SwiperSlide key={i}>
                                        <div className="feature-box-03">
                                            <div className="feature-content">
                                                <div className="icons">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <p>{ val.description }</p>
                                                <h6>{ val.author_name }</h6>
                                                <span>{ val.position }</span>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                })
                    }
                </Swiper>
            </div>
        </section>
        </>
    );
}
