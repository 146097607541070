

/*--------------------
* Service Section
----------------------*/
function Service(props) {
    return (
        <>
            <div className="lg:col-span-6">
                <div className={props.id.main + " feature-box-01"}>
                    <div className="icon"><i className={props.id.icon}></i></div>
                    <div className="feature-content">
                        <h5>{props.title}</h5>
                        <p>{props.description}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

/*--------------------
* Service List Section
----------------------*/
export default function ServiceList() {
    const service_list = [
        {id:{main:'bg-1', icon:'fas fa-globe'}, title:'Web Sites', description:'Elevate your online presence with cutting-edge web design! I specialize in creating visually stunning and user-friendly websites tailored to your unique brand.'},
        {id:{main:'bg-2', icon:'fas fa-laptop'}, title:'Softwares', description:' Unlock the full potential of your business through software solutions! As a software developer, I specialize in creating applications that not only meet but exceed your requirements.'},
        {id:{main:'bg-3', icon:'fas fa-chart-pie'}, title:'Data Science', description:'I specialize in extracting meaningful patterns from complex datasets. From predictive modeling and machine learning algorithms to advanced statistical analysis.'},
        {id:{main:'bg-4', icon:'fas fa-columns'}, title:'Professional Training', description:'Let\'s embark on a journey of knowledge and skill development, ensuring your team is well-equipped to meet the challenges of tomorrow.'},
    ]
    return (
        <>
           <section data-scroll-data="1" id="services" className="section services-section bg-gray" style={{ "backgroundImage": "url(img/effect/bg-effect-1.png)", "backgroundRepeat": "no-repeat", "backgroundSize": "cover"}}>
                <div className="container">
                    <div className="grid section-heading">
                        <div className="lg:col-span-6 text-center mx-auto">
                            <h3><span>My Services</span></h3>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-5 grid-cols-1 items-center">
                        {
                            service_list.map((val, i)=>{
                                return <Service key={i} id={val.id} title={val.title} description={val.description} />
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    );
}
